.accommodation__slider {
    position: relative;
    margin: 0 -15px;
    padding-top: 60px;

    &__title {
        text-align: center;
    }

    &__track {
        @include css-slider;
    }

    .slider__dots {
        position: static;
        justify-content: center;
        margin-top: 56px;
    }

    .slider__arrows {
        top: 0;
        border-radius: 0;
        line-height: 30px;
        height: 32px;
        width: 32px;
        margin-top: 0;
        vertical-align: middle;
        display: inline-block;
        border: 1px solid #FFF;
        background: #FFF;
        color: #bcbec0;
        cursor: pointer;
        text-align: center;
        font-size: 13px;

        &:after {
            content: none;
        }

        &.previous {
            right: 62px;
            left: auto;
        }

        &.next {
            right: 15px;
            left: auto;
        }

        &:hover {
            transform: none;
        }
    }

    &__item {
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;

        @include screen-768 {
            flex: 0 0 50%;
        }

        @include screen-992 {
            flex: 0 0 33.33333%;
        }
    }
}

.accommodation__gallery {
    @include screen-560 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 20px;
    }

    @include screen-768 {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }

    .accom-card {
        margin: 0 0 30px;

        @include screen-560 {
            margin-bottom: 0;
        }

        @include screen-768 {
            max-width: 560px;
        }

        &__price {
            background-color: rgba($color-grey-09, 0.8);
        }

        &__content {
            background-color: rgba($color-black, 0.8);
            color: $color-white;
        }

        &__heading {
            color: $color-white;
        }

        &__cta {
            display: none;
        }
    }

    &.unavailable {
        .accom-card:active img,
        .accom-card:focus img,
        .accom-card:hover img {
            opacity: 0.9;
            transform: none;
        }

        .accom-card__image {
            opacity: 0.6;

            @supports(filter: grayscale(1)) {
                opacity: 0.9;
                filter: grayscale(1)
            }
        }
    }

    &.accommodation-search-results {
        .accom-card__content {
            flex-wrap: wrap;
        }

        .accom-card__availability-status {
            display: block;
            flex: 1 0 100%;
            margin-bottom: 0;
        }
    }
}

.accom-card {
    position: relative;
    background-color: $color-white;
    color: $color-primary;
    display: block;
    border-radius: $standard-radius;
    margin: 0 15px;
    overflow: hidden;
    text-decoration: none;
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
        img {
            opacity: 0.9;
            transform: scale3d(1.1,1.1,1);
        }
    }

    img {
        user-select: none;
        transition:
            opacity 1s,
            transform 1s;
    }

    &__picture {
        position: relative;
        z-index: 0;
        display: block;
        overflow: hidden;

        @supports(aspect-ratio: 3 / 2) {
            aspect-ratio: 3 / 2;
        }
    }

    &__price {
        position: absolute;
        top: -6px;
        left: -6px;
        padding: 16px 10px 10px 16px;
        max-width: 86px;
        width: 100%;
        background: #bcbec0;
        color: #FFF;
        border-radius: $standard-radius;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1.1;
        z-index: 2;

        span {
            display: block;
            font-size: 22px;
            letter-spacing: 1px;
            font-weight: 700;

            &.accom-card__price__label {
                font-size: 80%;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    &__content {
        background: #FFF;
        display: block;
        position: relative;
        padding: 15px 20px;
        color: #00737a;
        overflow: auto;
        display: flex;
        justify-content: space-between;
    }

    &__heading {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: $fw-medium;
        margin-bottom: 0;
    }

    &__availability-status {
        display: none;
    }

    &__cta {
        font-size: $font-body;
        color: $color-grey-04;
        font-weight: $fw-light;
        text-transform: capitalize;
        margin-bottom: 0;
    }
}

.bg-primary .accom-card {
    color: $color-primary;
}






