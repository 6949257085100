/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.4rem;
  --h1-font-size: 3.2rem;
  --h1-line-height: normal;
  --h2-font-size: 2.6rem;
  --h2-line-height: normal;
  --h3-font-size: 2.1rem;
  --h3-line-height: normal;
  --h4-font-size: 1.8rem;
  --h4-line-height: normal;
  --h5-font-size: 1.8rem;
  --h5-line-height: normal;
  --h6-font-size: 1.6rem;
  --h6-line-height: normal;
  /* [02] */
  --giga-font-size: 8rem;
  --mega-font-size: 6.4rem;
  --kilo-font-size: 5.6rem;
  --milli-font-size: 1rem;
  --centi-font-size: 1.2rem;
}

/*
Basic system font stacks
*/
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url(//fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url(//fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxP.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: local("Rubik"), local("Rubik-Regular"), url(//fonts.gstatic.com/s/rubik/v8/iJWKBXyIfDnIV7nBrXk.ttf) format("truetype");
}
@font-face {
  font-display: block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: local("Rubik Medium"), local("Rubik-Medium"), url(//fonts.gstatic.com/s/rubik/v8/iJWHBXyIfDnIV7Eyjmmd8WU.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v16/jizaRExUiTo99u79D0KEwA.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0Uw.ttf) format("truetype");
}
@font-face {
  font-display: swap;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v19/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYiFV0Uw.ttf) format("truetype");
}
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@-webkit-keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@-webkit-keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@-webkit-keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Mixins ---- */
:root {
  --border-width: 2px;
  --border-radius: 6px;
}

/* --------------------------------
Text Link
----------------------------------- */
/* --------------------------------
Sizes
----------------------------------- */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
CSS Slider
------------------------------*/
/* ------------------------------------------------------
**** MODULE CORE
------------------------------------------------------ */
.accom-search {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 0;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 16px;
  display: grid;
  gap: 16px;
}
@media screen and (min-width: 87.5em) {
  .accom-search {
    gap: 24px;
  }
}
.accom-search__item {
  display: grid;
}
.accom-search__item .button {
  margin-top: auto;
}
.accom-search__item .field-element {
  position: relative;
  margin: auto 0 0;
}
.accom-search__item--title {
  background-color: #00737a;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.accom-search__item--title h3 {
  color: inherit;
  margin-bottom: 0;
}
@media screen and (min-width: 35em) {
  .accom-search__item--title {
    grid-column: span 2;
  }
}
@media screen and (min-width: 48em) {
  .accom-search__item--title {
    grid-column: span 3;
  }
}
@media screen and (min-width: 62em) {
  .accom-search__item--title {
    grid-column: span 1;
  }
}
@media screen and (min-width: 35em) {
  .accom-search__item--submit {
    grid-column: span 2;
  }
}
@media screen and (min-width: 48em) {
  .accom-search__item--submit {
    grid-column: span 1;
  }
}

.property-status {
  padding: 0.75em 1em;
  line-height: normal;
  border: 2px solid #0192d0;
  border-radius: 8px;
}
.property-status .property-status__title {
  color: #0192d0;
}
.property-status.available {
  border: 2px solid #0E6F22;
}
.property-status.available .property-status__title {
  color: #0E6F22;
}
.property-status.unavailable {
  border: 2px solid #A72D00;
}
.property-status.unavailable .property-status__title {
  color: #A72D00;
}
.property-status__title {
  display: block;
  font-weight: 800;
  text-transform: uppercase;
}
.property-status > *:last-child {
  margin-bottom: 0;
}

.accom-search.sidebar-widget {
  padding: 30px;
}
.accom-search.sidebar-widget .accom-search__item--title {
  background-color: #ffffff;
  color: #1e1e1e;
  margin-left: 0;
  padding-bottom: 0;
  text-transform: none;
  text-align: left;
}
.accom-search.sidebar-widget .accom-search__item--title h3 {
  text-transform: uppercase;
  font-size: 18px !important;
}
.accom-search.sidebar-widget .accom-search__item--title p {
  font-size: var(--body-font-size);
  margin-bottom: 0;
}

.mainbar .accom-search.search {
  display: grid;
  gap: 16px;
  box-shadow: none;
  background-color: #f5f5f5;
}
@media screen and (min-width: 35em) {
  .mainbar .accom-search.search {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .mainbar .accom-search.search {
    grid-template-columns: 2fr 2fr 1fr;
  }
}
@media screen and (min-width: 87.5em) {
  .mainbar .accom-search.search {
    gap: 24px;
  }
}

.accom-search.availability {
  box-shadow: none;
  padding: 0;
  border: none;
}

.accommodation__slider {
  position: relative;
  margin: 0 -15px;
  padding-top: 60px;
}
.accommodation__slider__title {
  text-align: center;
}
.accommodation__slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.accommodation__slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.accommodation__slider__track > * {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.accommodation__slider .slider__dots {
  position: static;
  justify-content: center;
  margin-top: 56px;
}
.accommodation__slider .slider__arrows {
  top: 0;
  border-radius: 0;
  line-height: 30px;
  height: 32px;
  width: 32px;
  margin-top: 0;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid #FFF;
  background: #FFF;
  color: #bcbec0;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
}
.accommodation__slider .slider__arrows:after {
  content: none;
}
.accommodation__slider .slider__arrows.previous {
  right: 62px;
  left: auto;
}
.accommodation__slider .slider__arrows.next {
  right: 15px;
  left: auto;
}
.accommodation__slider .slider__arrows:hover {
  transform: none;
}
.accommodation__slider__item {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
@media screen and (min-width: 48em) {
  .accommodation__slider__item {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 62em) {
  .accommodation__slider__item {
    flex: 0 0 33.33333%;
  }
}

@media screen and (min-width: 35em) {
  .accommodation__gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;
  }
}
@media screen and (min-width: 48em) {
  .accommodation__gallery {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}
.accommodation__gallery .accom-card {
  margin: 0 0 30px;
}
@media screen and (min-width: 35em) {
  .accommodation__gallery .accom-card {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .accommodation__gallery .accom-card {
    max-width: 560px;
  }
}
.accommodation__gallery .accom-card__price {
  background-color: rgba(30, 30, 30, 0.8);
}
.accommodation__gallery .accom-card__content {
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
}
.accommodation__gallery .accom-card__heading {
  color: #ffffff;
}
.accommodation__gallery .accom-card__cta {
  display: none;
}
.accommodation__gallery.unavailable .accom-card:active img,
.accommodation__gallery.unavailable .accom-card:focus img,
.accommodation__gallery.unavailable .accom-card:hover img {
  opacity: 0.9;
  transform: none;
}
.accommodation__gallery.unavailable .accom-card__image {
  opacity: 0.6;
}
@supports (filter: grayscale(1)) {
  .accommodation__gallery.unavailable .accom-card__image {
    opacity: 0.9;
    filter: grayscale(1);
  }
}
.accommodation__gallery.accommodation-search-results .accom-card__content {
  flex-wrap: wrap;
}
.accommodation__gallery.accommodation-search-results .accom-card__availability-status {
  display: block;
  flex: 1 0 100%;
  margin-bottom: 0;
}

.accom-card {
  position: relative;
  background-color: #ffffff;
  color: #00737a;
  display: block;
  border-radius: 6px;
  margin: 0 15px;
  overflow: hidden;
  text-decoration: none;
  overflow: hidden;
}
.accom-card:hover img, .accom-card:focus img, .accom-card:active img {
  opacity: 0.9;
  transform: scale3d(1.1, 1.1, 1);
}
.accom-card img {
  user-select: none;
  transition: opacity 1s, transform 1s;
}
.accom-card__picture {
  position: relative;
  z-index: 0;
  display: block;
  overflow: hidden;
}
@supports (aspect-ratio: 3/2) {
  .accom-card__picture {
    aspect-ratio: 3/2;
  }
}
.accom-card__price {
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 16px 10px 10px 16px;
  max-width: 86px;
  width: 100%;
  background: #bcbec0;
  color: #FFF;
  border-radius: 6px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.1;
  z-index: 2;
}
.accom-card__price span {
  display: block;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 700;
}
.accom-card__price span.accom-card__price__label {
  font-size: 80%;
  font-weight: 400;
  text-transform: uppercase;
}
.accom-card__content {
  background: #FFF;
  display: block;
  position: relative;
  padding: 15px 20px;
  color: #00737a;
  overflow: auto;
  display: flex;
  justify-content: space-between;
}
.accom-card__heading {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}
.accom-card__availability-status {
  display: none;
}
.accom-card__cta {
  font-size: var(--body-font-size);
  color: #bcbec0;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 0;
}

.bg-primary .accom-card {
  color: #00737a;
}

.stay-card-list {
  --gutter-width: 30px;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.stay-card {
  display: block;
  position: relative;
  background-color: #ffffff;
  color: #00737a;
  display: block;
  border-radius: 6px;
  text-decoration: none;
  margin-bottom: var(--gutter-width);
}
.stay-card__link-wrapped-picture {
  display: block;
}
.stay-card__link-wrapped-picture:hover img, .stay-card__link-wrapped-picture:focus img, .stay-card__link-wrapped-picture:active img {
  transform: scale(1.2);
}
.stay-card__link-wrapped-picture:hover .stay-card__picture:after, .stay-card__link-wrapped-picture:focus .stay-card__picture:after, .stay-card__link-wrapped-picture:active .stay-card__picture:after {
  right: 0;
}
.stay-card__picture {
  display: block;
  position: relative;
  z-index: 0;
  border-radius: 6px;
  overflow: hidden;
  background: #474747;
  background-image: url(../assets/logo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}
@supports (aspect-ratio: 3/2) {
  .stay-card__picture {
    aspect-ratio: 3/2;
  }
}
.stay-card__picture:after {
  content: "\e619";
  text-indent: 0;
  line-height: normal;
  text-shadow: none;
  font-family: icomoon;
  font-size: 15px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 0;
  right: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: block;
  opacity: 0.9;
  vertical-align: middle;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: right 250ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stay-card__picture:hover:after, .stay-card__picture:focus:after, .stay-card__picture:active:after {
  right: 0;
}
.stay-card__picture img {
  user-select: none;
  transition: transform 250ms ease;
}
@supports (aspect-ratio: 3/2) {
  .stay-card__picture img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.stay-card__price {
  text-align: right;
}
.stay-card__price__quantity {
  display: block;
  color: #bcbec0;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
}
.stay-card__price__label {
  display: block;
  font-size: var(--centi-font-size);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
}
.stay-card__content {
  background: #FFF;
  display: block;
  position: relative;
  color: #1e1e1e;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.stay-card__heading {
  font-family: Rubik;
  font-weight: 500;
  font-weight: 100;
  letter-spacing: 0.8px;
  font-style: normal;
  line-height: 1;
  font-size: 21px;
  color: #636363;
  margin: 20px 0 10px;
}
.stay-card__heading a {
  color: inherit;
}
.stay-card__description {
  font-size: var(--centi-font-size);
}
.stay-card__room-attributes {
  list-style-type: none;
  padding: 0;
  display: flex;
}
.stay-card__room-attributes li {
  flex: 0 0 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 3px;
  margin-left: 8px;
}
.stay-card__room-attributes li svg {
  width: 14px;
  height: 14px;
}
.stay-card__room-attributes li svg path,
.stay-card__room-attributes li svg circle,
.stay-card__room-attributes li svg ellipse,
.stay-card__room-attributes li svg rect,
.stay-card__room-attributes li svg line,
.stay-card__room-attributes li svg polygon {
  fill: #ffffff;
}
.stay-card__cta {
  margin-bottom: 0;
}
.stay-card__cta .button {
  display: block;
  width: 100%;
}

#room-template h3,
#room-template h4 {
  color: #1e1e1e;
  font-weight: 700;
}
#room-template h3 {
  font-size: 32px;
}
#room-template h4 {
  font-size: 28px;
}
#room-template .accom-card__heading {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.room-template__title span {
  display: block;
  font-size: var(--body-font-size);
  color: #bcbec0;
}
.room-template__price {
  text-align: right;
}
.room-template__price__quantity {
  display: block;
  color: #00737a;
  display: block;
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
}
.room-template__price__label {
  display: block;
  font-size: var(--centi-font-size);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
}
.room-template__price__label.from {
  text-align: left;
}

.room-amenities__list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.room-amenities__list__item {
  flex: 0 0 33.3333%;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.room-amenities__list__item:before {
  content: "";
  display: block;
  height: 1.5em;
  width: 1.5em;
  border-radius: 1.5em;
  background-image: url(../assets/icon-system/icon_form_tick-sm-white.svg);
  background-color: #00737a;
  background-size: 16px;
  background-position: center;
  margin-right: 0.5em;
}