
.accom-search {
    background: $color-white;
    border: 1px solid $color-grey-01;
    border-radius: 0;
    box-shadow: $spacing $spacing $spacing*2 rgba($color-black, .2);
    text-align: left;
    padding: $spacing*2;
    display: grid;
    gap: $spacing*2;

    @include screen-560 {
        // grid-template-columns: 1fr 1fr;
    }

    @include screen-992 {
        // grid-template-columns: 1.25fr 1fr 1fr 160px;
    }

    @include screen-1200 {
        // grid-template-columns: 1.75fr 1.75fr 1.5fr 1fr;
    }

    @include screen-1400 {
        // grid-template-columns: 2fr 2fr 2fr 1fr;
        gap: $spacing*3;
    }

    &__item {
        display: grid;

        .button {
            margin-top: auto;
        }

        .field-element {
            position: relative;
            margin: auto 0 0;
        }
    }

    &__item--title {
        background-color: $color-primary;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
        margin-left: -$spacing*2;
        margin-right: -$spacing*2;
        margin-top: -$spacing*2;
        padding-top: $spacing*2;
        padding-bottom: $spacing*2;

        h3 {
            color: inherit;
            margin-bottom: 0;
        }

        @include screen-560 {
            grid-column: span 2;
        }

        @include screen-768 {
            grid-column: span 3;
        }

        @include screen-992 {
            grid-column: span 1;
        }
    }

    &__item--submit {
        @include screen-560 {
            grid-column: span 2;
        }

        @include screen-768 {
            grid-column: span 1;
        }
    }
}

// .call-us-cta {
//     a {
//         color: currentColor;
//         text-decoration-color: rgba($color-black, 0);

//         &:hover,
//         &:focus,
//         &:active {
//             text-decoration-color: rgba($color-black, 1);
//         }
//     }
// }

.property-status {
    padding: 0.75em 1em;
    line-height: normal;

    border: 2px solid $color-utility-neutral;
    border-radius: 8px;

    .property-status__title {
        color: $color-utility-neutral;
    }

    &.available {
        border: 2px solid $color-utility-positive;

        .property-status__title {
            color: $color-utility-positive;
        }
    }

    &.unavailable {
        border: 2px solid $color-utility-alert;

        .property-status__title {
            color: $color-utility-alert;
        }
    }

    &__title {
        display: block;
        font-weight: $fw-bold;
        text-transform: uppercase;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.accom-search.sidebar-widget {
    padding: 30px;

    .accom-search__item--title {
        background-color: $color-white;
        color: $color-body-font;
        margin-left: 0;
        padding-bottom: 0;
        text-transform: none;
        text-align: left;

        h3 {
            text-transform: uppercase;
            font-size: 18px!important;
        }

        p {
            font-size: $font-body;
            margin-bottom: 0;
        }
    }
}

.mainbar .accom-search.search {
    display: grid;
    gap: $spacing*2;
    box-shadow: none;
    background-color: $color-grey-01;

    @include screen-560 {
        grid-template-columns: 1fr 1fr;
    }

    @include screen-992 {
        grid-template-columns: 2fr 2fr 1fr;
    }

    @include screen-1400 {
        gap: $spacing*3;
    }
}

.accom-search.availability {
    box-shadow: none;
    padding: 0;
    border: none;
}
