
#room-template {
    h3,
    h4 {
        color:#1e1e1e;
        font-weight:700;
    }

    h3 {
        font-size:32px;
    }

    h4 {
        font-size:28px;
    }

    .accom-card__heading {
        font-size: 16px;
        font-weight: $fw-medium;
        color: $color-white;
    }
}

.room-template {
    &__title {
        span {
            display: block;
            font-size: $font-body;
            color: $color-grey-04;
        }
    }

    &__price {
        text-align: right;

        &__quantity {
            display: block;
            color: $color-primary;
            display: block;
            font-weight: 700;
            font-size: 32px;
            line-height: normal;
        }

        &__label {
            display: block;
            font-size: var(--centi-font-size);
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1;

            &.from {
                text-align: left;
            }
        }
    }
}


.room-amenities {

    &__list {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        &__item {
            flex: 0 0 33.3333%;
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            &:before {
                content: "";
                display: block;
                height: 1.5em;
                width: 1.5em;
                border-radius: 1.5em;
                background-image: url(../assets/icon-system/icon_form_tick-sm-white.svg);
                background-color: $color-primary;
                background-size: 16px;
                background-position: center;
                margin-right: 0.5em;
            }
        }
    }
}
