.stay-card-list {
    --gutter-width: 30px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.stay-card {
    display: block;
    position: relative;
    background-color: $color-white;
    color: $color-primary;
    display: block;
    border-radius: $standard-radius;
    text-decoration: none;
    margin-bottom: var(--gutter-width);

    &__link-wrapped-picture {
        display: block;

        &:hover,
        &:focus,
        &:active {
            img {
                transform:scale(1.2)
            }

            .stay-card__picture:after {
                right: 0;
            }
        }
    }

    &__picture {
        display: block;
        position: relative;
        z-index: 0;
        border-radius: $standard-radius;
        overflow: hidden;
        background: $color-grey-07;
        background-image: url(../assets/logo.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;

        @supports(aspect-ratio: 3 / 2) {
            aspect-ratio: 3 / 2;
        }

        &:after {
            content: '\e619';

            text-indent: 0;
            line-height: normal;
            text-shadow: none;
            font-family: icomoon;
            font-size: 15px;
            color: #fff;
            -webkit-font-smoothing: antialiased;

            position: absolute;
            left: 0;
            right: 100%;
            top: 0;
            bottom: 0;

            overflow: hidden;

            display: block;
            opacity: .9;
            vertical-align: middle;
            margin: 0;
            background: rgba($color-black, 0.5);
            z-index: 1;

            transition:
                right 250ms ease;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                right: 0;
            }
        }

        img {
            user-select: none;
            transition:
                transform 250ms ease;

            @supports(aspect-ratio: 3/2) {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    &__price {
        text-align: right;

        &__quantity {
            display: block;
            color: $color-grey-04;
            display: block;
            font-weight: 700;
            font-size: 18px;
            line-height: normal;
        }

        &__label {
            display: block;
            font-size: var(--centi-font-size);
            font-weight: 400;
            text-transform: uppercase;
            line-height: 1;
        }
    }

    &__content {
        background: #FFF;
        display: block;
        position: relative;
        color: $color-body-font;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__heading {
        font-family: Rubik;
        font-weight: 500;
        font-weight: 100;
        letter-spacing: .8px;
        font-style: normal;
        line-height: 1;
        font-size: 21px;
        color: $color-grey-06;
        margin: 20px 0 10px;

        a {
            color: inherit;
        }
    }

    &__description {
        font-size: var(--centi-font-size);
    }

    &__room-attributes {
        list-style-type: none;
        padding: 0;
        display: flex;

        li {
            flex: 0 0 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-grey-09;
            border-radius: 3px;
            margin-left: 8px;

            svg {
                width: 14px;
                height: 14px;

                @include svg-contents {
                    fill: $color-white;
                }
            }
        }
    }

    &__cta {
        margin-bottom: 0;

        .button {
            display: block;
            width: 100%;
        }
    }
}
